@import '../header.scss';

.padding-right {
	padding-right: 9px !important;
}

.focus-padding {
	padding: 2%;
}

.main-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.header-tab {
		a span {
			color: #0f4291;
		}
	}
}

button {
	border: unset;
	background-color: unset;

	svg {
		color: #0f4291;
	}
}

@media only screen and (max-width: 992px) {
	#logo {
		margin-top: 3px;
	}
}

a#marketplaceLink {
	color: #005a9e !important;
}
