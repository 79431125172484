.filterContainer {
    direction: row; 
    padding: 1rem;
    justify-content: flex-start;

}

.selectComponent {
    background-color: pink;
}

.MuiButton-root:focus-visible {
    text-decoration: none;
    outline: 2px solid #000000;
    outline-offset: 0.1em;
}