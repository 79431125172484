#header {
	top: 0;
	left: 0;
}

#marketplaceTab,
#shopnowTab,
#myAccountMenu {
	display: flex;
	flex-direction: row;
	gap: 3px;
	align-items: center;
	height: auto;
	padding: 13px 15px 10px 15px;
	text-decoration: unset;
	margin-bottom: 6px;
}

#myAccountMenu {
	margin-bottom: 7px;
	span {
		color: #0f4291;
	}
}

.pad-right-30 {
	padding-right: 35px;
}

.visibility {
	visibility: visible !important;
}

[class^='icon-']:before,
[class*=' icon-']:before {
	margin: 0; /* custom */
}

/* ==========================================================================
   Header
========================================================================== */

.top-header {
	height: 51px;
	z-index: 3;
	width: 100%;
	background-color: #f5f5f5;
	min-width: 320px;
	border-bottom: solid 3px #b5c8e8;
}

#logo {
	margin: 6px 20px 0px 0px;
}

#logo img {
	width: 127px;
	height: 26px;
}

/* center header */
#header .header-container {
	height: 46px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 4px;
}

#header .header-tab {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

/* end center header */

ul.top-right-menu {
	font-size: 15px;
	list-style: none;
	padding: 5px 0px 0px 0px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
}

ul.top-right-menu > li > a > i {
	font-size: 20px;
}

ul.top-right-menu > li {
	position: relative;
	display: inline-block;
}

ul.top-right-menu > li + li {
	margin-left: 20px;
}

ul.top-right-menu > li > a {
	color: #323d42;
}

ul.top-right-menu > li > a:hover {
	text-decoration: none;
}

ul.top-right-menu > li > a:hover i {
	background: #323d42;
	color: #fff;
	text-decoration: none;
}

ul.top-right-menu .caret {
	color: #323d42;
	padding: 0px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px dashed;
}

ul.menu-user {
	padding: 0px;
	list-style: none;
}

ul.top-right-menu li .notifications-number {
	color: #323d42;
	width: auto;
	font-size: 10px;
	position: absolute;
	right: -12px;
	top: 0px;
	font-weight: normal;
}

.top-right-menu .dropdown-toggle:focus {
	outline: 2px solid #2095f2;
}

ul.menu-user > li {
	display: inline-block;
}

ul.menu-user > li > a {
	color: #323d42;
	height: 52px;
	padding: 15px;
	display: block;
	border-radius: 14px 14px 0px 0px;
	text-decoration: none;
}

ul.menu-user > li > a:hover span {
	text-decoration: underline;
}

ul.menu-user > li > a:hover i {
	background: #323d42;
	color: #fff;
}

ul.menu-user > li > a:hover .caret {
	background: #323d42;
	color: #fff;
}

ul.menu-user > li > a:hover i.icon-exc-my-account {
	color: #323d42;
	background: transparent;
}

ul.menu-user > li > a.active {
	background-color: #b3c635;
}

ul.menu-user > li > a > i {
	font-size: 20px;
	color: #323d42;
	/*margin-right: 5px;*/
}

a:hover {
	text-decoration: none;
}

a {
	margin-top: 5px;
}

.k-button {
	background: #13499f;
	color: #fff;
	text-transform: capitalize;
	line-height: 1.5;
	letter-spacing: 1.25px;
	border-radius: 4px;
	min-height: 36px;
	font-size: 14px;
	padding: 6px 16px 10px 16px;
	font-weight: bold;
	display: block;
	box-shadow:
		0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	align-items: center;
}

.k-button:hover {
	background-color: #0b2d65;
	border-color: #0b2d65;
	color: white;
}

.non-loggedin .page-title {
	font-size: 16px;
	border-left: 1px solid #323d42;
	margin: 18px 0;
	padding: 0 16px;
	font-family: 'Helvetica Neue LT W01_71488914', sans-serif, Arial;
}

.header-tab {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.header-tab ul.nav-tabs {
	font-weight: bold;
	border: none;
	display: flex;
	flex-direction: row;
	list-style: none;
	gap: 8px;
	align-items: center;
	padding: 0px;
	cursor: pointer;
}

.header-tab ul.nav-tabs > li {
	margin: 0px;
}

.header-tab ul.nav-tabs > li > a {
	font-size: 15px;
	color: #323d42;
	line-height: 1;
	height: 52px;
	padding: 13px 15px 13px 15px;
	border-radius: 14px 14px 0px 0px;
}

.header-tab ul.nav-tabs > li.disabled > a {
	font-size: 15px;
	color: #696969;
}

.header-tab ul.nav-tabs > li > a > span {
	display: inline-block;
}

.header-tab ul.nav-tabs > li > a i {
	font-size: 20px;
	color: #323d42;
}

.header-tab .nav-tabs > li.active > a:hover,
.header-tab .nav > li > a:focus,
.header-tab .nav > li > a:hover {
	border-color: #b5c8e8;
	background-color: #b5c8e8;
}

.header-tab .nav > li > a:focus {
	background-color: transparent;
	outline: solid 2px #2095f2;
	outline-radius: 14px 14px 0px 0px;
	-moz-outline-radius: 14px 14px 0px 0px;
}

.header-tab .nav-tabs > li.active > a,
.header-tab .nav-tabs > li.active > a:focus,
.header-tab .nav-tabs > li.active > a:hover {
	color: #0f4291;
	border-color: #b5c8e8;
	background-color: #b5c8e8;
}

.header-tab ul.nav-tabs > li:hover > a > span {
	text-decoration: underline;
}

.header-tab .nav-tabs > li.active > a i,
.header-tab .nav-tabs > li.active > a:focus i,
.header-tab .nav-tabs > li.active > a:hover i {
	color: #0f4291;
}

.header-tab .nav-tabs > li.active > a,
.header-tab .nav-tabs > li.active > a:focus,
.header-tab .nav-tabs > li.active > a:hover {
	color: #0f4291;
	border-color: #b5c8e8;
	background-color: #b5c8e8;
}

.header-tab ul.nav-tabs > li > a {
	font-size: 15px;
	color: #0f4291;
	line-height: 1;
	height: 52px;
	padding: 13px 15px 13px 15px;
}

.header-tab .nav-tabs>li.active>a:hover, .header-tab .nav>li>a:focus, .header-tab .nav>li>a:hover {
    border-color: #b5c8e8;
    background-color: #eef2f9;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0
}

.top-bar {
	color: #fff;
	width: 100%;
	top: 60px;
	z-index: 2;
	position: fixed;
	display: inline-block;
	padding: 15px 20px 15px 0px;
	border-bottom: solid 3px #bcc927;
	height: 66px;
	min-width: 320px;
}

.modal-content {
	padding-bottom: 0px;
	color: #444444;
	border-radius: 0px;
	margin: 0px auto;
	border: solid 1px #999999;
	background-color: #fff;
	-webkit-box-shadow: 20px 20px 27px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 20px 20px 27px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 20px 20px 27px 0px rgba(0, 0, 0, 0.2);
}

.modal-header {
	padding: 12px 16px;
	color: #fff;
	border-color: #323d42;
	background-color: #323d42;
}

.modal-title {
	font-size: 14px;
	max-width: calc(100% - 30px);
}

.modal-header .close {
	color: #fff;
	opacity: 1;
	top: 0;
	line-height: 1;
	font-size: 20px;
	position: relative;
	margin-top: 0;
	text-shadow: none;
}

.modal-header .help {
	color: #0f4291;
	opacity: 1;
	top: 0;
	line-height: 1;
	font-size: 20px;
	position: relative;
	margin: 0 10px;
	margin-top: 0;
	text-shadow: none;
}

.modal-header button:hover i {
	color: #0f4291;
	background: #fff;
}

.modal-header button:focus,
.modal-header button:active {
	outline: solid 2px #2095f2;
}

.bd-example-modal-sm .modal-dialog {
	max-width: 525px;
}

.bd-example-modal-sm .modal-lg {
	width: 100%;
	max-width: 685px;
}

.bd-example-modal-sm .modal-xl {
	width: 100%;
	max-width: 100%;
	padding: 0 10px;
}

.bd-example-modal-sm .modal-xl .modal-dialog {
	max-width: 100%;
}

.modal-content .modal-body {
	padding: 16px;
	/*border:1px solid #999;
	margin:16px;*/
}

.btn-morecriteria .hide-text {
	display: none;
}

.btn-morecriteria.show .show-text {
	display: none;
}

.btn-morecriteria.show .hide-text {
	display: inline-block;
}

.modal-dialog .modal-footer {
	margin-top: 0px;
	border: none;
	padding: 0 16px 16px;
}

.modal-dialog .modal-footer .btn-default {
	min-width: 100px;
}

.popup-advanced-search hr {
	border-color: #e1e1e1;
	margin-top: 10px;
	margin-bottom: 23px;
}

.link-criteria {
	margin-top: 15px;
	margin-bottom: 0;
}

.modal-footer .link-criteria {
	margin-right: 15px;
	margin-top: 0px;
	display: inline-block;
}

.row-advanced-search {
	width: 100%;
	padding-bottom: 10px;
	display: inline-block;
}

.row-advanced-search .first-column {
	width: 140px;
	padding-top: 4px;
}

.row-advanced-search .second-column {
	margin-left: 142px;
}

.row-advanced-search .second-column .btn-group.bootstrap-select {
	width: 100%;
}

.row-advanced-search .second-column p {
	font-size: 11px;
	color: #b6b6b6;
	margin: 10px 0px;
}

.row-advanced-search input[type='text'].input-lastname,
.row-advanced-search input[type='text'].input-firstname {
	width: 48%;
}

.row-advanced-search input[type='text'].input-firstname {
	margin-left: 4%;
}

.row-advanced-search .col-one,
.row-advanced-search .col-two {
	width: 48%;
}

.row-advanced-search .col-two {
	margin-left: 4%;
}

.row-advanced-search .btn-group {
	width: 100%;
}

.row-advanced-search .btn-group > .btn.multiselect {
	width: 100%;
	min-width: inherit;
}

.btn-sort > a {
	display: block;
	padding: 0px 5px 0px 5px;
	color: #fff;
	font-size: 20px;
}

.btn-sort > a:hover {
	color: #bcc927;
	text-decoration: none;
}

.btn-sort > a:focus {
	outline: solid 2px #2095f2;
}

.btn-sort.nobg a {
	background: none;
}

.btn-sort .dropdown-menu {
	min-width: 40px;
}

.btn-sort .dropdown-menu ul.list-common {
	padding: 10px 0;
	min-width: 180px;
}

.btn-sort .dropdown-menu ul.list-common li {
	padding: 5px 20px;
}

.btn-search {
	width: 26px;
	height: 27px;
	right: 0px;
	top: 5px;
	font-size: 18px;
	line-height: 24px;
	border-top: solid 1px #999999;
	border-right: solid 1px #999999;
	border-bottom: solid 1px #999999;
	border-left: 0;
	padding: 0px;
	position: absolute;
	background: #fff;
	color: #323d43;
}

input[type='text'].input-reference.form-control {
	padding-right: 30px;
}

.select-dropdown {
	padding-right: 25px;
}

.select-dropdown:hover {
	text-decoration: none;
}

ul.dropdown-folder li {
	padding: 5px 10px 5px 20px;
}

ul.dropdown-folder li:first-child {
	padding: 17px 20px;
}

ul.dropdown-folder li:last-child {
	padding-bottom: 15px;
}

ul.dropdown-folder li:last-child a {
	padding: 0px;
	color: #2497f3;
	border-bottom: none;
	text-decoration: underline;
}

ul.dropdown-folder li a:hover {
	background-color: transparent;
}

ul.list-filter-right {
	padding-left: 0px;
	padding-right: 10px;
}

ul.list-filter-right .btn-sort {
	float: none;
	margin: 0px;
}

ul.list-filter li .dropdown-right > a {
	color: #fff;
}

ul.list-white-btns.list-green-btns li a {
	background-color: #fff;
}

ul.list-green-btns li img {
	width: 23px;
	margin-top: -3px;
}

ul.list-filter li h3 {
	margin: 0px;
	font-size: 18px;
	display: inline-block;
}

.row-column {
	width: 100%;
	position: relative;
	display: inline-block;
	/*overflow: hidden;*/
}

.row-column .page-content {
	margin-top: 0;
	margin-left: 320px;
	/*height: calc(100vh - 50px);
    //overflow: auto;*/
	padding: 0px 0 0 0;
	-webkit-transition: all 0.4s ease-out;
	-moz-transition: all 0.4s ease-out;
	transition: all 0.4s ease-out;
}

.full-column.row-column .page-content {
	margin-left: 0px;
}

.text-link {
	color: #bcc927;
	text-decoration: underline;
}

@media only screen and (min-width: 768px) {
	.modal-dialog {
		margin: 20px auto;
	}
}

.btn-big {
	min-width: 135px;
}

.btn-gray {
	color: #fff;
	background-color: #323d42;
}

.btn-gray:hover {
	color: #323d42;
	background-color: #d2d2d2;
}

ul.list-green-btns {
	list-style: none;
	padding: 0px;
	margin: 0px;
	display: inline-block;
}

ul.list-green-btns li {
	display: inline-block;
}

ul.list-green-btns li + li {
	margin-left: 5px;
}

ul.list-green-btns li a {
	width: 36px;
	height: 36px;
	color: #323d42;
	display: block;
	font-size: 20px;
	text-align: center;
	line-height: 36px;
	border-radius: 5px;
	border: 1px solid #989898;
	background-color: #bcc927;
}

ul.list-green-btns li a:hover {
	background-color: #323d43;
	color: #fff;
	/*border:1px solid #323d42;*/
}

ul.list-green-btns li a:focus {
	outline: solid 2px #2095f2;
}

.dropdown-right {
	float: right;
	margin-left: 8px;
	position: relative;
}

.dropdown-right > a {
	font-size: 20px;
	color: #323d42;
	line-height: 25px;
	padding-left: 5px;
	padding-right: 5px;
	display: inline-block;
}

.dropdown-right > a:hover i {
	color: #fff;
	background: #323d42;
}

.dropdown-right > a:focus {
	outline: solid 2px #2095f2;
}

/* share dropdown top menu */
.box-reports {
	min-height: 60px;
	margin-bottom: 15px;
	border: solid 1px #989898;
	padding: 12px 12px 12px 40px;
	position: relative;
	background-color: #f5f5f5;
}

.box-reports:hover {
	border-color: #b3c635;
	background-color: #e1e6bb !important;
}

.box-reports .reports-icon {
	font-size: 20px;
	left: 11px;
	top: 7px;
	color: #b3c635;
	position: absolute;
}

.box-reports h5 {
	color: #323d43;
	margin: 0px 0px 5px 0px;
	font-family: 'Helvetica Neue LT W01_71488914', sans-serif, Arial;
}

.box-reports h6 {
	margin: 0px;
	font-size: 12px;
	white-space: nowrap;
}

.navbar-collapse.in {
	overflow-y: visible;
}

.top-right-menu > .messages-menu > .dropdown-menu:after,
.top-right-menu > .messages-menu > .dropdown-menu:before {
	bottom: 100%;
	right: 24px;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.top-right-menu > .messages-menu > .dropdown-menu:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #fff;
	border-width: 8px;
	margin-right: -8px;
}

.top-right-menu > .messages-menu > .dropdown-menu:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #e1e1e1;
	border-width: 10px;
	margin-right: -10px;
}

.top-right-menu > .messages-menu > .dropdown-menu {
	width: 330px;
	right: -20px;
	padding: 0 0 0 0;
	margin: 10px 0 0 0;
	top: 100%;
	text-transform: none;
	max-height: 425px;
	border-color: #cfcfcf;
	color: #323d42;
	overflow: inherit;
}

.top-right-menu > .messages-menu > .dropdown-menu li.header {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	background-color: #ffffff;
	padding: 15px 10px;
	border-bottom: 1px solid #e1e1e1;
	color: #323d42;
	font-size: 14px;
	line-height: 1.4;
	z-index: 500;
}

.top-right-menu > .messages-menu > .dropdown-menu li.header span {
	font-size: 16px;
}

.top-right-menu > .messages-menu > .dropdown-menu li.header span i {
	font-size: 20px;
}

.top-right-menu > .messages-menu > .dropdown-menu > li.footer {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	font-size: 12px;
	background-color: #fff;
	padding: 10px 0;
	border-top: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
	color: #323d42;
	text-align: center;
	height: 60px;
}

.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover {
	text-decoration: none;
	font-weight: normal;
}

.top-right-menu > .messages-menu > .dropdown-menu > li .menu {
	max-height: 300px;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow-x: hidden;
}

.top-right-menu > .messages-menu > .dropdown-menu > li .menu .box-reports {
	margin: 0;
	border: 0;
	border: 1px solid transparent;
	border-bottom: 1px solid #e1e1e1;
	background: #fff;
	padding-right: 35px;
}

.top-right-menu > .messages-menu > .dropdown-menu > li .no-shares {
	border-bottom: 1px solid #e1e1e1;
	padding: 15px;
	margin: 0;
	font-size: 14px;
}

.top-right-menu > .messages-menu > .dropdown-menu > li .menu .box-reports h6 {
	font-size: 14px;
	white-space: normal;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.reports-icon {
	top: 10px;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.dropdown-right {
	position: absolute;
	top: 10px;
	right: 10px;
	float: none;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.dropdown-right
	> a {
	padding: 10px 15px;
	border: 0;
	line-height: 25px;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.dropdown-menu {
	max-width: 180px;
}

.top-right-menu
	> .messages-menu.notifications-warning
	> .dropdown-menu
	> li
	.menu
	.box-reports {
	line-height: 1.4;
	padding: 12px 35px 12px 50px;
}

.top-right-menu
	> .messages-menu.notifications-warning
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.dropdown-right {
	right: 0px;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.dropdown-right
	li
	a {
	padding: 10px 15px 10px 25px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid #d2d2d2;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.notifications-header
	h4 {
	margin: 0px;
	font-size: 14px;
	padding: 15px 12px;
	border-bottom: solid 1px #e1e1e1;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.notifications-header
	h4
	a:hover {
	color: #fff;
	background: #323d43;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports
	.btn.btn-secondary {
	min-width: 100px;
}

.top-right-menu
	> .messages-menu.notifications-warning
	> .dropdown-menu
	> li.footer {
	height: auto;
	padding: 11px 14px;
}

.top-right-menu
	> .messages-menu.notifications-warning.open
	> .dropdown-menu
	> li
	.menu {
	max-height: 500px;
}

.top-right-menu > .messages-menu.notifications-warning.open > .dropdown-menu {
	max-height: 630px;
}

.top-right-menu
	> .messages-menu.notifications-warning
	> .dropdown-menu
	> li.footer
	.link-light {
	display: inline-block;
	margin-top: 4px;
}

.top-right-menu
	> .messages-menu
	> .dropdown-menu
	> li
	.menu
	.box-reports:hover {
	border: 1px solid #696969;
	outline: 0;
}

//adding necessary old styles
.visible-xs {
	display: none;
}

/* ==========================================================================
Responsive styles - Non-Mobile First Method
========================================================================== */

/* Large Screens - Center and max out at 1600px */
@media only screen and (min-width: 1600px) {
	#container {
		display: flex;
		justify-content: center;
	}

	#header {
		display: flex;
		justify-content: center;
		padding: 0;
	}

	.header-container {
		width: 1600px;
		padding: 0 20px;
	}

	#body {
		width: 1600px;
	}

	#body .page-content > .inner-content {
		padding: 0 20px;
	}
}

@media only screen and (max-width: 1600px) {
	#body .page-content > .inner-content {
		padding: 0 20px;
	}

	ul.menu-user > li > a {
		padding: 15px 10px;
	}
}

@media only screen and (min-width: 1279px) and (max-width: 1600px) {
	ul.top-right-menu > li + li {
		margin-left: 10px;
	}
}
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1279px) {
	ul.menu-user > li > a {
		padding: 15px 13px;
	}

	.navbar-collapse {
		width: 100%;
		margin-top: -5px;
	}

	.navbar-toggle {
		display: block;
	}

	.navbar-toggle.collapsed {
		color: #323d43;
	}

	.navbar-toggle {
		color: #b3c635;
	}

	.navbar-toggle:hover i {
		color: #fff;
		background: #b3c635;
	}

	.navbar-collapse.collapse {
		display: none !important;
	}

	.navbar-collapse.collapse.in {
		display: block !important;
	}

	ul.top-right-menu {
		text-align: left;
		background-color: #fff;
		border: 1px solid #999999;
		padding: 0px;
		-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		font-size: 14px;
	}

	ul.top-right-menu li {
		display: block;
	}

	ul.top-right-menu li a {
		display: block;
		height: auto;
		border-radius: 0px;
		padding: 10px 15px 10px 25px;
		border-top: 1px solid transparent;
		border-bottom: 1px solid #d2d2d2;
	}

	ul.top-right-menu li a span.visible-xs {
		display: block !important;
	}

	ul.top-right-menu li a:focus {
		outline: solid 2px #2095f2;
		text-decoration: none;
	}

	ul.top-right-menu li a:hover {
		text-decoration: none;
		border-top: 1px solid #696969;
		border-bottom: 1px solid #696969;
		background: #e1e6bb;
	}

	ul.menu-user > li > a:hover i,
	ul.top-right-menu > li > a:hover i {
		background: transparent;
		color: #323d42;
	}

	ul.top-right-menu li a:hover span span {
		color: #323d42;
		text-decoration: underline;
	}

	ul.top-right-menu li:hover a {
		/*color: #323d43;*/
	}

	ul.top-right-menu li ul.menu-user li a span {
		width: auto;
	}

	ul.top-right-menu li ul.menu-user li a .caret {
		display: none;
	}

	ul.top-right-menu li .notifications-number {
		left: 10px;
		right: inherit;
	}

	ul.top-right-menu li a span {
		min-width: 130px;
		font-size: 14px;
		padding-left: 10px;
	}

	ul.top-right-menu li + li {
		margin-left: 0px;
	}

	ul.list-filter-right {
		padding: 0 10px 0px 10px;
	}

	.mr-10 {
		margin-right: 10px;
	}

	ul.list-filter li .btn {
		margin-top: 0px;
	}

	.dropdown-right {
		margin-left: 8px;
	}

	.dropdown-right > a {
		line-height: 36px;
	}

	.row-column .page-content {
		margin-top: 0;
	}

	.navbar-toggle {
		padding: 0px;
		margin-top: 15px;
		margin-right: 5px;
	}

	.top-right-menu > .messages-menu > .dropdown-menu {
		width: 328px;
		right: 0;
		position: absolute;
		top: 0;
		margin: 0;
		border: 0;
		box-shadow: none;
		margin-bottom: 20px;
	}

	.top-right-menu > .messages-menu > .dropdown-menu:after {
		display: none;
	}

	.top-right-menu > .messages-menu > .dropdown-menu:before {
		display: none;
	}

	.top-right-menu > .messages-menu.open {
		border: solid 1px #323d43;
		border-bottom: 0;
	}

	.top-right-menu > .messages-menu.open > .dropdown-menu {
		border: solid 1px #323d43;
		left: -2px;
		border-top: 0;
	}

	.top-right-menu > .messages-menu > .dropdown-menu > li.footer {
		border-bottom: 1px solid #323d43;
		border-radius: 0;
	}

	.top-right-menu > .messages-menu > .dropdown-menu {
		max-height: 432px;
	}

	.top-right-menu > .messages-menu.open > a {
		background: #e1e6bb;
	}

	.top-right-menu
		> .messages-menu.open
		> .dropdown-menu
		> li
		.menu
		.box-reports {
		background: #f5f5f5;
	}

	.top-right-menu > .messages-menu.open > .dropdown-menu li.header {
		background: #f5f5f5;
	}

	ul.menu-user > li > a > i {
		min-width: 20px;
		padding-right: 0px;
		text-align: center;
		display: inline-block;
	}

	.top-header {
		text-align: center;
	}

	.top-right-menu > li.dropdown.messages-menu:before {
		content: '';
		color: #323d43;
		width: 15px;
		padding-right: 10px;
		padding-left: 10px;
		float: left;
		font-family: 'excelleris';
		line-height: 50px;
	}

	.top-right-menu > li.dropdown.messages-menu:before {
		content: '\e82d';
	}

	.top-right-menu > li.dropdown.messages-menu.open:before {
		content: '\e82b';
	}
}

@media only screen and (max-width: 1200px) {
	.header-tab ul.nav-tabs > li > a {
		padding: 15px 15px 18px;
	}

	.adv-search {
		left: 4px;
		top: 7px;
		min-width: 180px;
	}

	ul.list-filter > li + li {
		margin-left: 5px;
	}
}

/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {
	.header-tab ul.nav-tabs > li > a {
		padding: 10px 7px 13px;
		height: 42px;
	}

	.top-header {
		height: 50px;
	}

	.top-bar {
		top: 50px;
	}

	#logo {
		margin: 0px 10px 0px 0px;
	}

	.navbar-toggle {
		margin-top: 10px;
		margin-bottom: 0;
	}

	ul.list-filter {
		padding: 0 10px 0px 10px;
	}

	ul.list-filter-right {
		padding: 0 10px 0px 10px;
	}

	ul.list-filter > li + li {
		margin-left: 5px;
	}

	ul.list-common > li + li {
		padding: 0;
	}

	ul.list-common li .form-control {
		width: 130px;
	}

	.menu-collapse .page-content {
		margin-left: 0;
	}

	.box-selected-fields {
		min-width: initial;
	}

	.top-right-menu > .messages-menu > .dropdown-menu > li .menu {
		max-height: 180px;
	}

	footer .dropdown-menu {
		left: 0;
	}

	.powered-by {
		margin-top: -70px;
	}
}

/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
	.heading-top {
		padding: 0px 0;
		margin: 0;
	}

	.top-bar {
		padding-right: 0;
	}

	.header-tab ul.nav-tabs {
		gap: 0px;
	}

	.header-tab ul.nav-tabs > li > a {
		padding: 10px 5px 13px;
	}

	.header-tab ul.nav-tabs > li > a > span {
		display: none;
	}

	.visible-xs {
		display: block;
	}

	#marketplaceTab,
	#shopnowTab,
	#myAccountMenu {
		padding: 13px 8px 10px 10px;
	}

	#header .header-tab{
		margin-left: 10px;
	}
}

/* Small Devices, Tablets */

/* Extra Small Devices, Phones */

@media only screen and (max-width: 767px) {
	.mCSB_inside > .mCSB_container {
		margin-right: 15px;
	}

	.row-column .page-content {
		padding-left: 0;
	}

	.btn-big {
		min-width: 90px;
	}

	.pull-right-xs {
		float: right;
	}

	.bootstrap-select .dropdown-menu {
		left: 0;
		right: initial;
	}

	.powered-by {
		width: 150px;
		height: 30px;
		margin-top: -110px;
	}

}

@media only screen and (max-width: 600px) {
	.powered-by {
		margin-top: 0;
	}
}

@media only screen and (max-width: 480px) {

	.top-header {
		padding: 0px 5px 0px 10px;
	}

	#logo {
		margin-top: 5px;
	}

	#logo img {
		max-width: 100px;
	}

	.powered-by {
		margin-top: 0;
	}

	.xs-center {
		text-align: center;
	}

	.non-loggedin .page-title {
		font-size: 14px;
		padding: 0 14px;
	}

	#header .header-tab{
		margin-left: 15px;
	}
}

/* Custom, iPhone Retina */

@media only screen and (max-width: 380px) {
	#header .header-tab{
		margin-left: 5px;
	}
}

@media only screen and (max-height: 768px) {
	.modal-content {
		max-height: 90vh;
		overflow-y: auto;
	}
}

//------------------------------------------------
//	icons
//-------------------------------------------------
@font-face {
	font-family: 'excelleris';
	src: url('../../assets/fonts/excelleris.eot');
	src:
		url('../../assets/fonts/excelleris.eot') format('embedded-opentype'),
		url('../../assets/fonts//excelleris.woff2') format('woff2'),
		url('../../assets/fonts//excelleris.woff') format('woff'),
		url('../../assets/fonts//excelleris.ttf') format('truetype'),
		url('../../assets/fonts//excelleris.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'excelleris';
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	color: #0f4291;
	/* opacity: .8; */
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: 0.2em;
	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */
	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-exc-2-files:before {
	content: '\e800';
}
/* '' */
.icon-exc-abnormal-folder:before {
	content: '\e801';
}
/* '' */
.icon-exc-abnormal-result:before {
	content: '\e802';
}
/* '' */
.icon-exc-account-settings:before {
	content: '\e803';
}
/* '' */
.icon-exc-acknowlege-alerts:before {
	content: '\e804';
}
/* '' */
.icon-exc-add:before {
	content: '\e805';
}
/* '' */
.icon-exc-add-appointment:before {
	content: '\e806';
}
/* '' */
.icon-exc-add-patient-folder:before {
	content: '\e807';
}
/* '' */
.icon-exc-add-report-to-folder:before {
	content: '\e808';
}
/* '' */
.icon-exc-all-reports-folder:before {
	content: '\e809';
}
/* '' */
.icon-exc-animated-busy:before {
	content: '\e80a';
}
/* '' */
.icon-exc-appointment-reminder:before {
	content: '\e80b';
}
/* '' */
.icon-exc-appointments:before {
	content: '\e80c';
}
/* '' */
.icon-exc-archived:before {
	content: '\e80d';
}
/* '' */
.icon-exc-archived-report:before {
	content: '\e80e';
}
/* '' */
.icon-exc-arrow-down:before {
	content: '\e80f';
}
/* '' */
.icon-exc-arrow-left:before {
	content: '\e810';
}
/* '' */
.icon-exc-arrow-right:before {
	content: '\e811';
}
/* '' */
.icon-exc-arrow-up:before {
	content: '\e812';
}
/* '' */
.icon-exc-attachment:before {
	content: '\e813';
}
/* '' */
.icon-exc-blood-monitoring:before {
	content: '\e814';
}
/* '' */
.icon-exc-call-support:before {
	content: '\e815';
}
/* '' */
.icon-exc-cancel:before {
	content: '\e816';
}
/* '' */
.icon-exc-chart:before {
	content: '\e817';
}
/* '' */
.icon-exc-close:before {
	content: '\e818';
}
/* '' */
.icon-exc-close-side-panel:before {
	content: '\e819';
}
/* '' */
.icon-exc-shopping-cart:before {
	content: '\e81a';
}
/* '' */
.icon-exc-column-hover-sort:before {
	content: '\e81b';
}
/* '' */
.icon-exc-column-sort-ascending:before {
	content: '\e81c';
}
/* '' */
.icon-exc-column-sort-descending:before {
	content: '\e81d';
}
/* '' */
.icon-exc-commands-horizontal-menu-dots:before {
	content: '\e81e';
}
/* '' */
.icon-exc-commands-vertical-menu-dots:before {
	content: '\e81f';
}
/* '' */
.icon-exc-critical-folder:before {
	content: '\e820';
}
/* '' */
.icon-exc-critical-notifications:before {
	content: '\e821';
}
/* '' */
.icon-exc-critical-result:before {
	content: '\e822';
}
/* '' */
.icon-exc-dashboard-tab:before {
	content: '\e823';
}
/* '' */
.icon-exc-date-picker:before {
	content: '\e824';
}
/* '' */
.icon-exc-delegation-ends:before {
	content: '\e825';
}
/* '' */
.icon-exc-delegation-starts:before {
	content: '\e826';
}
/* '' */
.icon-exc-doctor:before {
	content: '\e827';
}
/* '' */
.icon-exc-download:before {
	content: '\e828';
}
/* '' */
.icon-exc-download-pdf:before {
	content: '\e829';
}
/* '' */
.icon-exc-dr-folder:before {
	content: '\e82a';
}
/* '' */
.icon-exc-drop-down-menu-down:before {
	content: '\e82b';
}
/* '' */
.icon-exc-drop-down-menu-left:before {
	content: '\e82c';
}
/* '' */
.icon-exc-drop-down-menu-right:before {
	content: '\e82d';
}
/* '' */
.icon-exc-drop-down-menu-up:before {
	content: '\e82e';
}
/* '' */
.icon-exc-edit:before {
	content: '\e82f';
}
/* '' */
.icon-exc-email-notification:before {
	content: '\e830';
}
/* '' */
.icon-exc-email-notification-o:before {
	content: '\e831';
}
/* '' */
.icon-exc-emr-pulled:before {
	content: '\e832';
}
/* '' */
.icon-exc-enrolled-patients:before {
	content: '\e833';
}
/* '' */
.icon-exc-enroll-patient-in-program:before {
	content: '\e834';
}
/* '' */
.icon-exc-event-log:before {
	content: '\e835';
}
/* '' */
.icon-exc-event-log-filled:before {
	content: '\e836';
}
/* '' */
.icon-exc-expand-widget:before {
	content: '\e837';
}
/* '' */
.icon-exc-export-details:before {
	content: '\e838';
}
/* '' */
.icon-exc-eorder:before {
	content: '\e839';
}
/* '' */
.icon-exc-filter-off:before {
	content: '\e83a';
}
/* '' */
.icon-exc-filter-off-o:before {
	content: '\e83b';
}
/* '' */
.icon-exc-filter-on:before {
	content: '\e83c';
}
/* '' */
.icon-exc-filter-on-o:before {
	content: '\e83d';
}
/* '' */
.icon-exc-flagged-folder:before {
	content: '\e83e';
}
/* '' */
.icon-exc-flagged-folder-o:before {
	content: '\e83f';
}
/* '' */
.icon-exc-flagged-patient-folder:before {
	content: '\e840';
}
/* '' */
.icon-exc-flagged-report-folder:before {
	content: '\e841';
}
/* '' */
.icon-exc-flask:before {
	content: '\e842';
}
/* '' */
.icon-exc-graph:before {
	content: '\e843';
}
/* '' */
.icon-exc-hamburger-menu:before {
	content: '\e844';
}
/* '' */
.icon-exc-healthcare-team-members:before {
	content: '\e845';
}
/* '' */
.icon-exc-important-notifications:before {
	content: '\e846';
}
/* '' */
.icon-exc-inbox:before {
	content: '\e847';
}
/* '' */
.icon-exc-inbox-folder:before {
	content: '\e848';
}
/* '' */
.icon-exc-info-help-tip:before {
	content: '\e849';
}
/* '' */
.icon-exc-infusion:before {
	content: '\e84a';
}
/* '' */
.icon-exc-link:before {
	content: '\e84b';
}
/* '' */
.icon-exc-linked-application:before {
	content: '\e84c';
}
/* '' */
.icon-exc-logout:before {
	content: '\e84d';
}
/* '' */
.icon-exc-manual-sort:before {
	content: '\e84e';
}
/* '' */
.icon-exc-manual-test-entry:before {
	content: '\e84f';
}
/* '' */
.icon-exc-mark-as-read:before {
	content: '\e850';
}
/* '' */
.icon-exc-mark-as-unread:before {
	content: '\e851';
}
/* '' */
.icon-exc-maximize-widget:before {
	content: '\e852';
}
/* '' */
.icon-exc-menu-widget:before {
	content: '\e853';
}
/* '' */
.icon-exc-minimize-widget:before {
	content: '\e854';
}
/* '' */
.icon-exc-minor-account:before {
	content: '\e855';
}
/* '' */
.icon-exc-minus-circle:before {
	content: '\e856';
}
/* '' */
.icon-exc-missed-appointment:before {
	content: '\e857';
}
/* '' */
.icon-exc-mobile-sms-notification:before {
	content: '\e858';
}
/* '' */
.icon-exc-move-transport-patient:before {
	content: '\e859';
}
/* '' */
.icon-exc-multi-patient-report:before {
	content: '\e85a';
}
/* '' */
.icon-exc-my-account:before {
	content: '\e85b';
}
/* '' */
.icon-exc-my-reports:before {
	content: '\e85c';
}
/* '' */
.icon-exc-my-shared-reports:before {
	content: '\e85d';
}
/* '' */
.icon-exc-next-item-horizontal:before {
	content: '\e85e';
}
/* '' */
.icon-exc-next-item-vertical:before {
	content: '\e85f';
}
/* '' */
.icon-exc-normal-notifications:before {
	content: '\e860';
}
/* '' */
.icon-exc-note-added:before {
	content: '\e861';
}
/* '' */
.icon-exc-note-edited:before {
	content: '\e862';
}
/* '' */
.icon-exc-not-enrolled:before {
	content: '\e863';
}
/* '' */
.icon-exc-notes-1:before {
	content: '\e864';
}
/* '' */
.icon-exc-notes-2:before {
	content: '\e865';
}
/* '' */
.icon-exc-off:before {
	content: '\e866';
}
/* '' */
.icon-exc-open-side-panel:before {
	content: '\e867';
}
/* '' */
.icon-exc-patient:before {
	content: '\e868';
}
/* '' */
.icon-exc-patient-details:before {
	content: '\e869';
}
/* '' */
.icon-exc-patients-tab:before {
	content: '\e86a';
}
/* '' */
.icon-exc-patient-summary:before {
	content: '\e86b';
}
/* '' */
.icon-exc-payment:before {
	content: '\e86c';
}
/* '' */
.icon-exc-people:before {
	content: '\e86d';
}
/* '' */
.icon-exc-pharmanet:before {
	content: '\e86e';
}
/* '' */
.icon-exc-pharmanet-tab:before {
	content: '\e86f';
}
/* '' */
.icon-exc-poc-source:before {
	content: '\e870';
}
/* '' */
.icon-exc-previous-item-horizontal:before {
	content: '\e871';
}
/* '' */
.icon-exc-previous-item-vertical:before {
	content: '\e872';
}
/* '' */
.icon-exc-primary-caregiver:before {
	content: '\e873';
}
/* '' */
.icon-exc-print:before {
	content: '\e874';
}
/* '' */
.icon-exc-print-pdf:before {
	content: '\e875';
}
/* '' */
.icon-exc-problem-error-issue:before {
	content: '\e876';
}
/* '' */
.icon-exc-program-monitoring:before {
	content: '\e877';
}
/* '' */
.icon-exc-programs-monitoring:before {
	content: '\e878';
}
/* '' */
.icon-exc-programs-tab:before {
	content: '\e879';
}
/* '' */
.icon-exc-question-help-1:before {
	content: '\e87a';
}
/* '' */
.icon-exc-question-help-2:before {
	content: '\e87b';
}
/* '' */
.icon-exc-question-help-3:before {
	content: '\e87c';
}
/* '' */
.icon-exc-recent-activities:before {
	content: '\e87d';
}
/* '' */
.icon-exc-recently-archived-folder:before {
	content: '\e87e';
}
/* '' */
.icon-exc-reference-ranges:before {
	content: '\e87f';
}
/* '' */
.icon-exc-reference-resource-links:before {
	content: '\e880';
}
/* '' */
.icon-exc-referrals:before {
	content: '\e881';
}
/* '' */
.icon-exc-refresh:before {
	content: '\e882';
}
/* '' */
.icon-exc-remove:before {
	content: '\e883';
}
/* '' */
.icon-exc-remove-o:before {
	content: '\e884';
}
/* '' */
.icon-exc-reports-tab:before {
	content: '\e885';
}
/* '' */
.icon-exc-report-status-flagged:before {
	content: '\e886';
}
/* '' */
.icon-exc-report-status-flagged-o:before {
	content: '\e887';
}
/* '' */
.icon-exc-report-status-printed:before {
	content: '\e888';
}
/* '' */
.icon-exc-reset-dates:before {
	content: '\e889';
}
/* '' */
.icon-exc-retransmissions:before {
	content: '\e88a';
}
/* '' */
.icon-exc-search:before {
	content: '\e88b';
}
/* '' */
.icon-exc-security-message:before {
	content: '\e88c';
}
/* '' */
.icon-exc-settings:before {
	content: '\e88d';
}
/* '' */
.icon-exc-shared-report:before {
	content: '\e88e';
}
/* '' */
.icon-exc-share-grant:before {
	content: '\e88f';
}
/* '' */
.icon-exc-share-receive:before {
	content: '\e890';
}
/* '' */
.icon-exc-sharing:before {
	content: '\e891';
}
/* '' */
.icon-exc-skipped-emr-pulled:before {
	content: '\e892';
}
/* '' */
.icon-exc-skipped-inbox-folder:before {
	content: '\e893';
}
/* '' */
.icon-exc-sort-alpha-a-z:before {
	content: '\e894';
}
/* '' */
.icon-exc-sort-alpha-z-a:before {
	content: '\e895';
}
/* '' */
.icon-exc-sort-numeric-1-9:before {
	content: '\e896';
}
/* '' */
.icon-exc-sort-numeric-9-1:before {
	content: '\e897';
}
/* '' */
.icon-exc-success-1:before {
	content: '\e898';
}
/* '' */
.icon-exc-success-2:before {
	content: '\e899';
}
/* '' */
.icon-exc-success-3:before {
	content: '\e89a';
}
/* '' */
.icon-exc-success-4:before {
	content: '\e89b';
}
/* '' */
.icon-exc-success-5:before {
	content: '\e89c';
}
/* '' */
.icon-exc-suspended-program-patient:before {
	content: '\e89d';
}
/* '' */
.icon-exc-table:before {
	content: '\e89e';
}
/* '' */
.icon-exc-text-docs-1:before {
	content: '\e89f';
}
/* '' */
.icon-exc-text-docs-2:before {
	content: '\e8a0';
}
/* '' */
.icon-exc-text-docs-3:before {
	content: '\e8a1';
}
/* '' */
.icon-exc-text-docs-4:before {
	content: '\e8a2';
}
/* '' */
.icon-exc-toggle-flag:before {
	content: '\e8a3';
}
/* '' */
.icon-exc-toggle-flag-o:before {
	content: '\e8a4';
}
/* '' */
.icon-exc-transcriptions:before {
	content: '\e8a5';
}
/* '' */
.icon-exc-unack-critical-notifications:before {
	content: '\e8a6';
}
/* '' */
.icon-exc-unack-important-notifications:before {
	content: '\e8a7';
}
/* '' */
.icon-exc-unack-warning-notifications:before {
	content: '\e8a8';
}
/* '' */
.icon-exc-undefined-gender:before {
	content: '\e8a9';
}
/* '' */
.icon-exc-un-interpretable-circle:before {
	content: '\e8aa';
}
/* '' */
.icon-exc-un-interpretable-square:before {
	content: '\e8ab';
}
/* '' */
.icon-exc-unread-report-circle:before {
	content: '\e8ac';
}
/* '' */
.icon-exc-unread-report-square:before {
	content: '\e8ad';
}
/* '' */
.icon-exc-updated-report:before {
	content: '\e8ae';
}
/* '' */
.icon-exc-viewed:before {
	content: '\e8af';
}
/* '' */
.icon-exc-warning-caution:before {
	content: '\e8b0';
}
/* '' */
.icon-exc-warning-notifications:before {
	content: '\e8b1';
}
/* '' */
.icon-exc-move:before {
	content: '\e8b2';
}
/* '' */
.icon-exc-arrow-range:before {
	content: '\e8b3';
}
/* '' */
.icon-exc-circle-node-o:before {
	content: '\e8b4';
}
/* '' */
.icon-exc-circle-node:before {
	content: '\e8b5';
}
/* '' */
.icon-exc-arrow-right-thin:before {
	content: '\e8b6';
}
/* '' */
.icon-exc-send-arrow:before {
	content: '\e8b7';
}
/* '' */
.icon-exc-arrow-left-thin:before {
	content: '\e8b8';
}
/* '' */
.icon-exc-globe:before {
	content: '\e8b9';
}
/* '' */
.icon-exc-device:before {
	content: '\e8ba';
}
/* '' */
.icon-exc-comments:before {
	content: '\e8bb';
}
/* '' */
.icon-exc-family-account:before {
	content: '\e8bc';
}
/* '' */
.icon-exc-get-in-line:before {
	content: '\e8bd';
}
/* '' */
.icon-exc-location-marker:before {
	content: '\e8be';
}
/* '' */
.icon-exc-location-marker-o:before {
	content: '\e8bf';
}
/* '' */
.icon-exc-next-left:before {
	content: '\e8c0';
}
/* '' */
.icon-exc-next-right:before {
	content: '\e8c1';
}
/* '' */
.icon-exc-collapse-widget:before {
	content: '\e8c2';
}
/* '' */
.icon-exc-map:before {
	content: '\e8c3';
}
/* '' */
.icon-exc-user-o:before {
	content: '\e8c4';
}
/* '' */
.icon-exc-minus:before {
	content: '\e8c5';
}
/* '' */
.icon-exc-analytics-trend:before {
	content: '\e8c6';
}
/* '' */
.icon-exc-plus-circle-o:before {
	content: '\e8c7';
}
/* '' */
.icon-exc-minus-circle-o:before {
	content: '\e8c8';
}
/* '' */
.icon-exc-updated-report-check:before {
	content: '\e8c9';
}
/* '' */
.icon-exc-add-member:before {
	content: '\e8ca';
}

.icon-exc-eye:before {
	content: '\e8cb';
}
/* '' */
.icon-exc-eye-slash:before {
	content: '\e8cc';
}
/* '' */
.icon-calendar:before {
	content: '\f073';
}
/* '' */
.icon-house-call:before {
	content: '\e817';
}
/* '' */
.icon-checkin:before {
	content: '\e817';
}
/* '' */
.icon-results:before {
	content: '\e817';
}
/* '' */
.icon-shopr:before {
	content: '\e817';
}
/* '' */
.icon-linkedin:before {
	content: '\f08c';
}
/* '' */
.icon-twitter:before {
	content: '\f099';
}
/* '' */
.icon-facebook:before {
	content: '\f09a';
}
/* '' */
.icon-youtube:before {
	content: '\f166';
}