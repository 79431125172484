.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.bold {
	font-weight: 700;
}
