.App {
	text-align: center;
}

.sup {
	font-size: 70%;
	text-decoration: underline;
	border-bottom: none;
}

.gm-style .gm-style-iw-c {
	padding: 0;
	box-shadow: none;
}

button.gm-ui-hover-effect,
button.gm-ui-hover-effect:hover {
	display: none;
	width: 0;
	height: 0;
	opacity: 0
}

.gm-style-iw-tc,
.gm-style-iw-tc:after {
	height: 0;
	display: none;
}

.gm-style-iw-c {
	background-color: transparent !important;
}

@media print {

	body {
		visibility: hidden;
	}

	#section-to-print {
		visibility: visible;
		height: 100% !important;
	}
}