.dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #e51937;
	border-radius: 50%;
	margin-right: 13px;
}

.search {
	div {
		gap: 8px;
	}

	label {
		display: flex;
		flex-direction: column;
		align-items: baseline;

		h1 {
			font-size: 24px;
			flex-direction: row;
			align-items: center;
			display: flex;
		}

		span:last-of-type {
			text-indent: 24px;
		}
	}
}

.locationFinder {
	position: relative;
	width: 100%;

	input {
		font-size: 16px;
		height: 36px;
		width: 577px;
		padding: 8px 6px 8px 20px;

		&::placeholder {
			opacity: 1 !important;
			color: #595959 !important;
		}
	}

	button {
		position: absolute;
		right: 4px;
		height: 44px;
		line-height: 24px;
		font-size: 16px;
		border-radius: 80px;
		border: unset;
		box-shadow: none;
		min-width: 44px;
		min-height: 44px;
		gap: 10px;
	}
}

.predictions {
	display: flex;
	flex-direction: column;
	gap: 20px;
	box-shadow: 0px 6px 6px 2px #59595926;
	z-index: 15001;
	top: 60px;
	background: white;
	border-radius: 1rem;

	li {
		font-size: 1rem;
		gap: 8px;
	}
}

.error {
	margin-left: 22px;
	margin-top: -5px;
	color: #A80014;
	font-size: 12px;
}

@media screen and (max-width: 1024px) and (min-width: 750px) {
	.locationFinder {
		input {
			width: 75%;
		}
	}
}

@media (max-width: 430px) {
	.locationFinder {
		input {
			width: 75%;
		}
	}
}

@media (max-width: 390px) {
	.locationFinder {
		input {
			width: 72%;
		}
	}
}

@media (max-width: 375px) {
	.locationFinder {
		input {
			width: 71%;
		}
	}
}

@media (max-width: 360px) {
	.locationFinder {
		input {
			width: 70%;
		}
	}
}

@media (max-width: 320px) {
	.locationFinder {
		input {
			width: 70%
		}
	}
}

@media (max-width: 430px) {
	.search label span:last-of-type {
		text-indent: 0;
	}
}

@media (max-width: 430px) {
	.predictions {
		gap: 10px;
	}
}