.book-home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #dde5f1;
	border-radius: 16px;
	gap: 1rem;
	border: unset;
	box-shadow: none;
	color: #13499f;
	padding: 1.5rem 2rem;

	span {
		display: flex;
		gap: 12px;
		flex-direction: row;
		align-items: center;
	}

	svg {
		width: 16.89px;
		height: 17.02px;
	}
}

@media (max-width: 480px) {
	.book-home {
		width: 100%;		
	}
}

@media (max-width: 400px) {
	.book-home {
		padding: 12px;

	}
}



