@import '../header.scss';

a #marketplaceLink {
	color: #005a9e !important;
}

/* styles.css */

.header__top {
	height: 40px;
}

.header__container {
	padding-left: 60px !important;
}

.selectProvince {
	background-color: #0c306b;
	height: 40px;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 40px !important;
}

.selectProvinceIcon {
	color: white !important;
}

.selectProvinceSelect {
	color: white !important;
}

.linkContainer {
	display: flex;
	margin-left: 60px;
	align-items: center;
	gap: 15px;
}

.linkText {
	font-size: 14px !important;
	font-weight: 400 !important;
	border-bottom: 1px solid #fff !important;
}

.navLink {
	text-decoration: none;

}

.navLink:hover {
	text-decoration: underline;
}

.province-name {
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 40px !important;
	padding-top: 0px !important;
}

.logoImage {
	max-width: 150px;
}

.buttonContainer {
	display: flex;
	justify-content: end;
	padding-right: 36px;
}

.button {
	font-size: 13px;
	line-height: 16px;
	height: 38px;
}

.button:hover {
	color: #fff !important;
	background-color: #DB3140 !important;
}

.header__footer {
	padding-top: 0 !important;
}

.linkContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.searchForm {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.searchTextField {
	margin-right: 10px;
}

.popupCollapse {
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer__link {
	font-size: 14px !important;
	font-weight: 400 !important;
}

.footer__link:hover {
	border-top: 2px solid red;
	display: inline-block;
	padding: 4px 0;
}

.header-drawer{
	display: flex;
	flex-direction: column !important;
	gap: 10px;
}

.header-social-links {
	display: flex;
	height: 40px;
    background: #0c306b;
	align-items: center;
	padding: 10px;
	gap: 5px;
}

.header-links-title {
	background-color: #0c306b !important;
	color: white !important;
}

.header-links-title:hover {
	background-color: rgb(219, 49, 64) !important;
}

.header-links-services {
	background-color: #11479d !important;
}

.header-links-services:hover {
	background-color: rgb(219, 49, 64) !important;
}

.header-links-service {
	color: white !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
	cursor: pointer;
}

.header-links-services-accordian{
	padding: 0 !important;
	margin: 0 !important;
}

.header-links-services-list {
	padding: 0 !important;
	margin: 0 !important;
}

.menu-button{
	color: #11479d !important;
	font-weight: bold !important;
}

.header__container__mobile{
	display: flex;
	flex-direction: row;
	padding-right: 15px;
	justify-content: space-around;
}

.header-mobile-section{
	display: flex;
	flex-direction: row;
	gap: 25px;
	padding-left: 15px;
	align-items: center;
}

.header-mobile-state{
	display: flex;
	float: right;
}

.header-section-maple{
	display: flex;
	flex-direction: row;
}

.header-body-logo {
	display: flex;
	justify-content: end;
}

.header-body {
	display: flex;
	padding: 10px;
}

.MuiButton-root:focus-visible {
    text-decoration: none ;
    outline: 2px solid #000000 ;
    outline-offset: 0.1em ;
}

.Mui-focusVisible:focus-visible {
	text-decoration: none ;
    outline: 2px solid #000000 ;
    outline-offset: 0.1em ;
}

.MuiInput-input:focus-visible {
	text-decoration: none;
    outline: 2px solid #000000;
    outline-offset: 0.1em ;
}

@media only screen and (max-width: 768px) {
	.searchForm {
		display: flex;
		justify-content: start;
		padding-left: 5px;
	}

	.searchTextField {
		border-bottom: 2px solid #A80014;
	}

	.header-body-logo {
		justify-content: start !important;
		padding: 10px 25px 25px;
	}

	.buttonContainer {
		display: block !important;
		padding-right: 0;
    	padding-left: 15px;
		background-color: white !important;
	}

	.button {
		margin: 5px !important;
		padding: 15px !important;
	}

	.selectProvince {
		height: 36px !important;
	}

	.header-body{
		padding: 0px;
	}
}