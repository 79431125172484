.location {
    border-bottom: 1px solid grey;
    box-shadow: none;
    height: auto;
    min-height: 5rem;
    padding: 1rem 0;

    .name-grid {
        height: 1.8rem;
    }

}

.location-stack {
    width: 100%;
    border-left: 2px solid grey;
    min-height: 4rem;
    height: auto;
    padding-left: 0.81rem;
    margin: 0.5rem 0;
    margin-bottom: 1rem
}

.show-appointment {
    z-index: -1;
    position: relative;
}

.dummy-image {
    max-height: 100%;
    width: 50vw;
    max-width: 100%;
}

.map-container {
    height: 100%;
    width: 100%;
}

.map-container-mobile {
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    height: 500px;
    position: sticky !important;
}

#map-stats-container {
	height: calc(100vh - 64px);
}