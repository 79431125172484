@import "../Footer.scss";

.footer__container {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid #E9E9E9;
}

.title {
    color: #666666;
    font-size: 15px !important;
    line-height: 1.6 !important;
}

.footer-heading-text {
    font-size: 15px !important;
    line-height: 1.6 !important;
    font-weight: 300 !important;
    color: #666666;
}

.footerTop {
    text-align: center;
    margin-top: 20px;
    padding-right: 55px;
    padding-left: 55px;
}

.footer-heading {
    margin-top: 20px !important;
}

.footerbody {
    padding: 60px 0;
    padding-right: 55px;
    padding-left: 55px;
}

.footer-listitem {
    width: 16%;
}

.link {
    font-size: 16px !important;
    line-height: 1.4 !important;
    font-weight: 400 !important;
    text-decoration: underline;    
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    text-decoration-color: #11479D !important;
}

.link:hover {
    cursor: pointer;
    color: #E31735 !important;
    border-color: #E31735 !important;
    text-decoration-color: #E31735 !important;
}

.subMenuItem {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #535E5F !important;
    text-decoration: underline;    
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    text-decoration-color: #535E5F !important;
}

.subMenuItem:hover {
    color: #E31735 !important;
    border-color: #E31735 !important;
    cursor: pointer;
    text-decoration: underline;    
    text-decoration-thickness: 1px;
    text-underline-offset: 4px; 
    text-decoration-color: #E31735 !important;
}

.listItem {
    padding-left: 0px !important;
}


.footer-links-container {
    background: #F5F9FB;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    padding-right: 55px;
    padding-left: 55px;
}

.footer-links-section {
    display: flex;
    flex-direction: row;
}

.footer-links-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    align-items: center;
}

.social-links {
    margin-top: 10px !important;
    text-align: right;
}

.social-links a {
    margin-left: 10px;
}

.footer-links-text {
    font-size: 13px !important;
    color: #666666 !important;
    font-weight: 400 !important;
    border-bottom: 1px solid #666666;
    width: fit-content;
}

.footer-links-text:hover {
    color: #E31735 !important;
    border-color: #E31735 !important;
}

.copyright {
    font-size: 13px !important;
    color: #666666 !important;
    font-weight: 400 !important;
    padding: 0 !important;
}

.social-buttons {
    color: #11479D !important;
    border-bottom: 1px solid #11479D;
    font-weight: bold !important;
    width: fit-content;
}

.footer-links-responsive {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.footer-assets {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

.footer-kronos{
    gap: 15px !important;
}

.footer-links-list-item{
    width: fit-content
}

.contact-us{
    color: #13499F !important;
    display: flex !important;
    margin-top: 52px;
}

@media only screen and (max-width: 768px) {
    // .footerTop{
    //     padding-left: 15px;
    //     padding-right: 15px;
    // }

    .footerbody  {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
    }

    .footer-section-list li{
        width: 50%;
        float: left;
        flex: 0 0 calc(33.33% - 10px);
    }

    .footer-links-section {
        display: flex;
        flex-direction: column;
        margin-top: 15px !important;
    }

    .footer-links-list {
        display: flex;
        flex-direction: column !important;
        width: 100%;
        gap: 15px;
        margin-top: 10px;
    }
    
    .footer-kronos{
        display: flex;
        flex-direction: row !important;
    }

    .social-links{
        margin-top: 20px !important;
    }

    .copyright {
        text-align: center;
    }

    .footer-links-responsive {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        padding-right: 15px;
        // padding-left: 15px;
    }

    .footer-listitem {
        width: 100%;
    }

    .contact-us{
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 1400px) {
    .contact-us{
        margin-top: 52px;
    }
}

@media only screen and (min-width: 1401px) {
    .contact-us{
        margin-top: 40px;
    }
}
