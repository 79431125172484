footer.page-footer {
	padding: 10px;
	padding-top: 20px;
	background-color: #f3f6fa;
}

.right {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.list-inline {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0px;
	padding: 0;
	flex-wrap: wrap;
	list-style: none;
	align-items: flex-start;

	li {
		display: flex;
		flex-direction: row;
		gap: 1px;
		align-items: flex-end;
	}

	a span {
		color: #13499f;

		&:hover {
			text-decoration: underline;
		}
	}
}

.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	p {
		margin: 10px;
	}
}

.view-icon-custom {
	position: relative;
	top: 7px px;
	margin-left: 3px;
}

.view-icon {
	content: url(../../assets/mcc-assets/img/view.svg);
}

select {
	border: unset;
	background: unset;
	color: #13499f;
	font-size: 1rem;
	margin-top: 5px;
	line-height: 1.5rem;
	font-family: 'Poppins';

	option {
		font-family: 'Poppins';
		color: #13499f;
	}
}
